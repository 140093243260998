import React from "react"
import Layout from "../components/layout"
import About from "../components/About"
import Features from "../components/Features"
import PageHeader from "../components/PageHeader"
import SEO from "../components/seo"

class AboutPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="About" />
        <PageHeader title="About" />
        <About />
        <div id="why" className="container">
          <Features />
          <div className="features-area pb-110">
            <div className="container">
              <h3 className="text-center">
                What makes trackmylifts different?
              </h3>
              <p>
                Ok, so there's already thousands of fitness apps out there. Why
                should you choose trackmylifts for recording your weight
                lifting? What makes it different? How does it produce fitness
                results for me? Here's why:
              </p>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div
                    className="features-wrapper text-center mb-30 wow fadeIn"
                    data-wow-delay="0.2s"
                  >
                    <div className="features-icon-img mb-20">
                      <img src="" alt="" />
                    </div>
                    <h4>It predicts the weight you should use</h4>
                    <p></p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div
                    className="features-wrapper text-center mb-30 wow fadeIn"
                    data-wow-delay="0.2s"
                  >
                    <div className="features-icon-img mb-20">
                      <img src="" alt="" />
                    </div>
                    <h4>It is driven by scientific research</h4>
                    <p></p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div
                    className="features-wrapper text-center mb-30 wow fadeIn"
                    data-wow-delay="0.4s"
                  >
                    <div className="features-icon-img mb-20">
                      <img src="" alt="" />
                    </div>
                    <h4>tailored workouts for your goal</h4>
                    <p>
                      Track your weight lifting performance on an easy to use
                      app interface so you can focus on what's important.
                      <br /> (Pumping iron)
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div
                    className="features-wrapper text-center mb-30 wow fadeIn"
                    data-wow-delay="0.6s"
                  >
                    <div className="features-icon-img mb-20">
                      <img src="" alt="" />
                    </div>
                    <h4>Meaningful Statistics</h4>
                    <p>
                      The app presents exercise specific data such as
                      predicted/achieved 1 rep max, best set performed, average
                      rest time and more!
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div
                    className="features-wrapper text-center mb-30 wow fadeIn"
                    data-wow-delay="0.6s"
                  >
                    <div className="features-icon-img mb-20">
                      <img src="" alt="" />
                    </div>
                    <h4>Request a feature</h4>
                    <p>
                      We give all of our users the ability to request a new
                      features they would like added to the app.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AboutPage
